

import React from 'react';
import ScienceExhibition from 'views/ScienceExhibition'

const ScienceClubpage = () => {
  return <ScienceExhibition />;
};

export default ScienceClubpage;

