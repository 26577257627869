import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/SchoolProgram/SciExhb/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/SciExhb/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/SciExhb/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/SciExhb/4.jpg';
// import p5 from 'assests/Photos/SchoolProgram/SciExhb/5.jpg';
import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/SchoolProgram/components/SidebarArticles/SidebarArticles';
import SideBarSci from 'views/ScienceClub/SideBarSci';

const ScienceExhibition = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/SciExhb/1.jpg`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/SciExhb/2.jpg`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/SciExhb/3.jpg`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/SciExhb/4.jpg`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/SciExhb/5.jpg`;
  

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Science Exhibition 2022-23<br/>
Wonders of Imagination
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
       Classes 6-8 , 29 October 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        To foster the student’s scientific temperament, ‘Wonders of Imagination – A Science Exhibition
was conducted on Saturday, 29 October 2022. The students of classes 6 to 8 made creative
working models depicting various concepts of Science. It evoked interest and curiosity in the
young minds.<br/>
Students had an opportunity to make simple but unique models which they did with great vigor
and enthusiasm. It was a different and unique experience for the students and served as a
memorable learning experience for all. It was heartening to note that, when given a chance, the
young enthusiastic participants can introspect, retrospect and come up with viable ways for
learning concepts of Science. These simple experiments helped children acquire new ways of
asking questions and understanding the World of Science.

        </Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
          “Everything is theoretically impossible until it is done"
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SideBarSci/>
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ScienceExhibition;